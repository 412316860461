declare global {
    interface Window { 
        BASE_DOMAIN: string
        API_PORT: string | undefined
    }
}

export const venueName = "The Wilds";
export const venueLongName = "The Wilds Golf Club";
export const venueAddress = "3151 Wilds Ridge Ct, Prior Lake, MN 55372";
export const venueTown = "Prior Lake, MN";
export const date = "October 7th, 2023";
export const month = "October";
export const monthNum = '10';
export const day = "7";
export const year = "2023";
export const isProd = process.env.NODE_ENV === 'production';
export const baseUrl: string = window.BASE_DOMAIN;
export const port: string = window.API_PORT || '';
export const protocol = baseUrl.includes('localhost') ? 'http://' : 'https://';
export const backendUrl = `${protocol}api.${baseUrl}${port === '' ? '' : `:${port}`}/api/`