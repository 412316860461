import { ImageListItem } from "@mui/material"
import { PhotoManifest } from "./PhotoPage"
import { useEffect, useRef, useState } from "react"


export interface PhotoProps {
    hostname: string
    item: PhotoManifest
    handleOpenImage: (url: string, photo: PhotoManifest) => void
}


export const Photo = ({hostname, item, handleOpenImage}: PhotoProps) => {

    return <ImageListItem key={item.path}>
    <img
        loading="lazy"
        src={`https://${hostname}/small-${item.path}`}
        height={item.height}
        width={item.width}
        onClick={() => handleOpenImage(`https://${hostname}/${item.path}`, item)}
        />
</ImageListItem>
}