import { Container, Grid, Typography } from "@mui/material"
import { day, monthNum, year } from "../Util/Constants"

export const Footer = () => {
    return <Container maxWidth={"xs"} >
            <Grid container direction={"row"} sx={{alignItems: "center", textAlign: 'center', alignContent: 'center'}}>
                <Grid item xs={4}/>
                <Grid item xs={4} sx={{paddingTop: '10vh', paddingBottom: "1vh"}}>
                    <Typography variant="h6" component="div" sx={{
                        color: "primary.main",
                        fontFamily: 'Quickpen',
                        fontWeight: 700,
                        fontSize: {xs: "2rem"},
                        letterSpacing: '.3rem',
                        textDecoration: 'none',}}>
                        B&S
                    </Typography>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={4}/>
                <Grid item xs={4} sx={{borderTop: "thin solid", paddingTop: "1vh", paddingBottom: "5vh"}}>
                    <Typography
                        sx={{color: "primary.main", fontSize: '1rem'}}
                        >
                        {monthNum}.{day}.{year}
                    </Typography>
                </Grid>
            </Grid>
    </Container>
}