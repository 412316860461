import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createTheme, LinkProps, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { BrowserRouter, NavLink, NavLinkProps, Route, Routes } from 'react-router-dom';
import { Footer } from './Footer/footer';
import { ThankYouPage } from './ThankYou/ThankYouPage';
import { PhotoPage } from './PhotoPage/PhotoPage';
import { weddingManifest } from './PhotoManifests/weddingPhotos';
import { photoboothManifest } from './PhotoManifests/photobooth';
import { honeymoonManifest } from './PhotoManifests/honeymoon';



const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, 'to'> & { href: NavLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <NavLink ref={ref} to={href} {...other} />;
});



const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Quickpen',
    ].join(','),
     
  },
  palette: {
    primary: { main: '#66051f' },
    secondary: { main: '#ffffff' },
    // secondary: { main: '#ebc8b2' },
//   error: { main: '#944342' },
//   info: { main: '#ebc8b2' }
// #521a19 #d19188 #ebc8b2 #944342
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <BrowserRouter>
        <div className='App'>
          {/* <InfoBar/> */}
          <Routes>
            <Route path="/" element={<ThankYouPage/>}/>
            <Route path="/weddingPhotos" element={<PhotoPage subtitle={true} title="Wedding Pictures" paths={weddingManifest} hostname={"photos.vullo.wedding"} />}/>
            <Route path="/photobooth" element={<PhotoPage  title="Photobooth Pictures" paths={photoboothManifest} hostname={"photobooth.vullo.wedding"} />}/>
            <Route path="/honeymoon" element={<PhotoPage  title="Honeymoon Pictures" paths={honeymoonManifest} hostname={"honeymoon.vullo.wedding"} />}/>
            {/* <Route path="/" element={<InfoPage />}/> */}
            {/* <Route path="/rsvp" element={<RSVPPage/>}/> */}
            {/* <Route path="/travel" element={<TravelPage/>}/> */}
            {/* <Route path="/faq" element={<QandA/>}/> */}
          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
