import { Close, Scale } from "@mui/icons-material"
import { Box, Container, Grid, Modal } from "@mui/material"
import Image from "mui-image"

export interface PhotoModalProps { 
    open: boolean
    handleClose: () => void
    photoUrl: string
    height: number
    width: number
    scale: number
}

export const PhotoModal = ({scale, open, handleClose, photoUrl, height, width}: PhotoModalProps) => {
    const actualHeight = height/scale
    const actualWidth = width/scale
    return <Modal
    
    open={open}
    onClose={handleClose}
    sx={{outline: 'none'}}
  >
    <Box sx={{outline: 'none'}} height={actualHeight} width={actualWidth} marginX={`${(window.innerWidth - actualWidth)/2}px`} marginY={`${(window.innerHeight - actualHeight)/2}px`} >
        <Image style={{outline: "none"}} src={photoUrl} height={actualHeight} width={actualWidth}></Image>
    </Box>
  </Modal>
}