import { ThemeContext } from "@emotion/react"
import { Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, Container, Grid, Grow, Typography } from "@mui/material"
const styles = {
    media: {
    //    height: 0,
    //    paddingTop: '56.25%' // 16:9
    },
    card: {
       position: 'relative',
    },
    overlay: {
       position: 'absolute',
       top: '60%',
       color: 'white',
       left: "0%",
       textAlign: "center",
    },
    mobileOverlay: {
        position: 'absolute',
        top: '90%',
        color: 'white',
        left: "10%",
        textAlign: "center",
    }
 }

export const ThankYouPage = () => {
   return <Box>
   <Container maxWidth={'lg'} sx={{display: {sm: "block", xs: "none",  md: "block", lg: "block", xl: "block"}, borderColor: "primary.main"}}>
    <Grid 
    container
    spacing={0}
    direction={"column"}
    alignItems="center"
    justifyContent="center"
    minHeight={"100vh"}>
        <Grid item xs={3}>
            <Grow in={true} timeout={3000}>
                <Typography
                    color="primary"
                    variant="h1"
                    noWrap
                    sx={{
                        fontFamily: 'Quickpen',
                        color: "primary.main",
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        lineHeight: 2,
                    }}>
                    Thank You
                </Typography>
            </Grow>
        </Grid>
        <Grid item xs={3}>
            <Grow in={true} timeout={6000}>
                <Typography
                    color="primary"
                    variant="h5"
                    noWrap
                    sx={{
                        fontFamily: 'Quickpen',
                        color: "primary.main",
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        lineHeight: 2,
                    }}>
                    - The Vullos
                </Typography>
            </Grow>
        </Grid>
        <Grid item xs={3} paddingTop={"10vh"}>
            <Grow in={true} timeout={6000}>
                <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} sx={{borderRight: "solid thin", borderColor: "#66051f", textAlign: "right"}} paddingRight={"2vw"}>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                // fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                            "It was like the best day of the last couple of years, possibly ever"
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                             - Brian
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{borderLeft: "solid thin", borderColor: "#66051f", textAlign: "left"}} paddingLeft={"2vw"}>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                // fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                            "We're so grateful for all the time spent, miles traveled, kind wishes, and generous gifts"
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                             - Samantha
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
        </Grid>
        <Grid item xs={3} paddingTop={"10vh"}>
            <Grow in={true} timeout={6000}>
                <Grid container direction="row" justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/photobooth">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/photobooth_photo.jpeg"/>
                                    <Typography
                                    color="primary"
                                    variant="h5"
                                    sx={{ ...styles.overlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Photobooth Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/weddingPhotos">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/wedding_photo.jpg"/>
                                    <Typography
                                    color="primary"
                                    variant="h5"
                                    sx={{ ...styles.overlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Wedding Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/honeymoon">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/honeymoon_photo.jpg"/>
                                    <Typography
                                    color="primary"
                                    variant="h6"
                                    sx={{ ...styles.overlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Honeymoon Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grow>
        </Grid>
    </Grid>

    </Container>

    <Container maxWidth={'xs'} sx={{display: {sm: "none", xs: "block",  md: "none", lg: "none", xl: "none"}, borderColor: "primary.main"}}>
    <Grid 
    container
    spacing={0}
    direction={"column"}
    alignItems="center"
    justifyContent="center"
    minHeight={"100vh"}>
        <Grid item xs={3} sx={{textAlign: "center"}}>
            <Grow in={true} timeout={3000}>
                <Typography
                    color="primary"
                    variant="h1"
                    noWrap
                    sx={{
                        fontFamily: 'Quickpen',
                        color: "primary.main",
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        lineHeight: 2,
                        wordWrap: "normal",
                        textOverflow: "clip"
                    }}>
                    Thank <br/>You
                </Typography>
            </Grow>
        </Grid>
        <Grid item xs={3}>
            <Grow in={true} timeout={6000}>
                <Typography
                    color="primary"
                    variant="h5"
                    noWrap
                    sx={{
                        fontFamily: 'Quickpen',
                        color: "primary.main",
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        lineHeight: 2,
                    }}>
                    - The Vullos
                </Typography>
            </Grow>
        </Grid>
        <Grid item xs={3} paddingTop={"10vh"}>
            <Grow in={true} timeout={6000}>
                <Grid container direction={"column"} justifyContent={"center"}>
                    <Grid item xs={6} sx={{borderBottom: "solid thin", borderColor: "#66051f", textAlign: "center"}} paddingBottom={"2vw"}>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                // fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                            "It was like the best day of the last couple of years, possibly ever"
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 500,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                             - Brian
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{borderTop: "solid thin", borderColor: "#66051f", textAlign: "center"}} paddingTop={"2vw"}>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                // fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                            "We're so grateful for all the time spent, miles traveled, kind wishes, and generous gifts"
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h5"
                            sx={{
                                fontFamily: 'Quickpen',
                                color: "primary.main",
                                fontWeight: 500,
                                letterSpacing: '.3rem',
                                lineHeight: 2,
                                wordWrap: "normal",
                                textOverflow: "clip"
                            }}>
                             - Samantha
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
        </Grid>
        <Grid item xs={3} paddingTop={"10vh"}>
            <Grow in={true} timeout={6000}>
                <Grid container direction="column" spacing={2} justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/photobooth">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/photobooth_photo.jpeg"/>
                                    <Typography
                                    color="primary"
                                    variant="h5"
                                    sx={{ ...styles.mobileOverlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Photobooth Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/weddingPhotos">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/wedding_photo.jpg"/>
                                    <Typography
                                    color="primary"
                                    variant="h5"
                                    sx={{ ...styles.mobileOverlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Wedding Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <Card sx={styles.card} raised>
                            <CardActionArea href="/honeymoon">
                                <CardMedia sx={styles.media}
                                    component="img"
                                    image="/honeymoon_photo.jpg"/>
                                    <Typography
                                    color="primary"
                                    variant="h6"
                                    sx={{ ...styles.mobileOverlay,
                                        fontFamily: 'Quickpen',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                Honeymoon Pictures
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grow>
        </Grid>
    </Grid>

    </Container>
    </Box>
}