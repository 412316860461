import { Box, Button, Container, Grid, Grow, ImageList, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { PhotoModal } from "./PhotoModal";
import { useNavigate } from "react-router-dom";
import { Photo } from "./Photo";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

export interface PhotoManifest {
    path: string,
    width: number,
    height: number
}

export interface PhotoPageProps {
    paths: PhotoManifest[]
    title: string
    hostname: string
    subtitle?: boolean
}

export const PhotoPage = ({paths, title, hostname, subtitle}: PhotoPageProps) => {
    const [openModal, setOpenModal] = useState(false)
    const [openImageHeight, setOpenImageHeight] = useState(0)
    const [openImageWidth, setOpenImageWidth] = useState(0)
    const [openPicUrl, setopenPicUrl] = useState("")
    const [openPicScale, setOpenPicScale] = useState(1)
    const [scrollable, setScrollable] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (window.scrollY != 0) {
            window.scrollTo(0, 0);
        }
    }, [])
    
    const handleOpenImage = (imageUrl: string, item: PhotoManifest) => {
        setopenPicUrl(imageUrl);
        setOpenPicScale(calculatePhotoScale(item));
        setOpenImageHeight(item.height);
        setOpenImageWidth(item.width);
        setOpenModal(true)
    }

    const handleCloseImage = () => {
        setOpenModal(false)
        setopenPicUrl("");
        setOpenPicScale(1);
        setOpenImageHeight(0);
        setOpenImageWidth(0);
    }

    const handleBack = () => {
        if (openModal) {
            handleCloseImage()
        } else {
            navigate("/")
        }
    }

    const calculatePhotoScale = (item: PhotoManifest) => {
        const scale =  [1,2,3,4,5].find((idx) => window.innerHeight > item.height/idx && window.innerWidth > item.width/idx)
        return scale ?? 5
    }

    return (
    <Box>
        {openModal && <PhotoModal height={openImageHeight} scale={openPicScale} width={openImageWidth} open={openModal} photoUrl={openPicUrl} handleClose={handleCloseImage} />}
        <Box sx={{display: {sm: "none", xs: "none",  md: "none", lg: "none", xl: "flex"}}}>
            <Button sx={{ zIndex: 9999, position: "fixed", top: "2vh", left: "2vw"}} onClick={handleBack} variant="contained">Back</Button>
            <Container maxWidth="xl" sx={{overflow: scrollable ? "auto": "hidden"}}>
                <Grid container direction={"column"}
                    spacing={0}
                    alignItems="center"
                    minHeight={"100vh"}>
                    <Grid item xs={12}>
                        <Grow in={true} timeout={3000} onEntered={() => setScrollable(true)}>
                            <Typography
                                    color="primary"
                                    variant="h1"
                                    noWrap
                                    sx={{
                                        fontFamily: 'Quickpen',
                                        color: "primary.main",
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                    {title}
                            </Typography>
                        </Grow>
                    </Grid>
                    {subtitle && <Grid item xs={12}>
                        <Grow in={true} timeout={3000}>
                            <Typography
                                color="primary"
                                variant="body1"
                                noWrap
                                sx={{
                                    color: "primary.main",
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    lineHeight: 2,
                                }}>
                                Captured by <a href="https://www.emilysphotography.blog">Emily's Photography</a>
                            </Typography>
                        </Grow>
                    </Grid>}
                    <Grid item xs={12} alignItems={"center"}>
                        <Grow in={true} timeout={3000}>       
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {paths.map((item) => (
                                <Photo {...{hostname, item, handleOpenImage}} />
                        ))}
                    </ImageList>
                    </Grow>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box sx={{display: {sm: "none", xs: "none",  md: "flex", lg: "flex", xl: "none"}}}>
            <Button sx={{ zIndex: 9999, position: "fixed", top: "2vh", left: "2vw"}} onClick={handleBack} variant="contained">Back</Button>
            <Container maxWidth="md">
                <Grid container direction={"column"}
                    spacing={0}
                    alignItems="center"
                    minHeight={"100vh"}>
                    <Grid item xs={12}>
                        <Grow in={true} timeout={3000}>
                            <Typography
                                    color="primary"
                                    variant="h2"
                                    noWrap
                                    sx={{
                                        fontFamily: 'Quickpen',
                                        color: "primary.main",
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                    {title}
                            </Typography>
                        </Grow>
                    </Grid>
                    {subtitle && <Grid item xs={12}>
                        <Grow in={true} timeout={3000}>
                            <Typography
                                color="primary"
                                variant="body1"
                                noWrap
                                sx={{
                                    color: "primary.main",
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    lineHeight: 2,
                                }}>
                                Captured by <a href="https://www.emilysphotography.blog">Emily's Photography</a>
                            </Typography>
                        </Grow>
                    </Grid>}
                    <Grid item xs={12} alignItems={"center"}>
                        <Grow in={true} timeout={3000}>
                        <ImageList variant="masonry" cols={2} gap={8}>
                            {paths.map((item) => (
                                <Photo key={item.path} {...{hostname, item, handleOpenImage}} />
                        ))}
                    </ImageList>
                    </Grow>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box sx={{display: {sm: "flex", xs: "flex",  md: "none", lg: "none", xl: "none"}}}>
            <Button sx={{ zIndex: 9999, position: "fixed", top: "2vh", left: "2vw"}} onClick={handleBack} variant="contained">Back</Button>
            <Container maxWidth="xs" sx={{marginTop: "10vh"}}>
                <Grid container direction={"column"}
                    spacing={0}
                    alignItems="center"
                    minHeight={"100vh"}>
                    <Grid item xs={12}>
                        <Grow in={true} timeout={3000}>
                            <Typography
                                    color="primary"
                                    variant="h5"
                                    noWrap
                                    sx={{
                                        fontFamily: 'Quickpen',
                                        color: "primary.main",
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        lineHeight: 2,
                                    }}>
                                    {title}
                            </Typography>
                        </Grow>
                    </Grid>
                    {subtitle && <Grid item xs={12}>
                        <Grow in={true} timeout={3000}>
                            <Typography
                                color="primary"
                                variant="caption"
                                noWrap
                                sx={{
                                    color: "primary.main",
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    lineHeight: 2,
                                }}>
                                Captured by <a href="https://www.emilysphotography.blog">Emily's Photography</a>
                            </Typography>
                        </Grow>
                    </Grid>}
                    <Grid item xs={12} alignItems={"center"} justifyContent={"center"}>
                        <Grow in={true} timeout={3000}>
                            <ImageList cols={1} gap={8}>
                                {paths.map((item) => (
                                    <Photo {...{hostname, item, handleOpenImage}} />
                                ))}
                    </ImageList>
                    </Grow>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </Box>)
}