export const photoboothManifest = [{path: "The_Vullos_photo_1.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_2.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_3.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_4.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_5.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_6.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_7.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_8.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_9.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_10.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_11.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_12.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_13.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_14.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_15.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_16.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_17.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_18.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_19.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_20.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_21.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_22.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_23.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_24.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_25.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_26.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_27.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_28.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_29.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_30.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_31.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_32.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_33.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_34.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_35.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_36.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_37.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_38.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_39.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_40.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_41.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_42.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_43.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_44.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_45.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_46.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_47.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_48.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_49.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_50.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_51.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_52.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_53.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_54.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_55.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_56.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_57.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_58.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_59.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_60.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_61.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_62.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_63.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_64.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_65.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_66.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_67.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_68.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_69.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_70.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_71.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_72.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_73.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_74.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_75.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_76.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_77.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_78.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_79.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_80.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_81.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_82.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_83.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_84.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_85.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_86.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_87.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_88.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_89.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_90.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_91.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_92.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_93.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_94.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_95.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_96.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_97.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_98.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_99.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_100.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_101.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_102.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_103.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_104.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_105.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_106.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_107.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_108.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_109.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_110.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_111.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_112.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_113.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_114.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_115.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_116.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_117.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_118.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_119.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_120.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_121.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_122.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_123.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_124.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_125.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_126.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_127.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_128.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_129.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_130.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_131.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_132.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_133.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_134.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_135.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_136.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_137.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_138.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_139.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_140.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_141.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_142.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_143.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_144.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_145.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_146.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_147.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_148.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_149.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_150.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_151.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_152.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_153.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_154.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_155.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_156.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_157.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_158.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_159.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_160.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_161.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_162.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_163.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_164.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_165.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_166.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_167.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_168.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_169.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_170.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_171.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_172.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_173.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_174.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_175.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_176.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_177.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_178.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_179.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_180.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_181.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_182.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_183.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_184.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_185.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_186.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_187.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_188.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_189.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_190.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_191.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_192.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_193.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_194.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_195.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_196.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_197.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_198.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_199.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_200.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_201.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_202.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_203.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_204.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_205.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_206.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_207.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_208.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_209.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_210.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_211.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_212.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_213.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_214.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_215.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_216.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_217.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_218.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_219.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_220.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_221.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_222.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_223.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_224.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_225.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_226.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_227.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_228.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_229.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_230.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_231.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_232.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_233.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_234.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_235.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_236.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_237.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_238.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_239.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_240.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_241.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_242.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_243.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_244.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_245.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_246.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_247.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_248.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_249.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_250.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_251.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_252.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_253.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_254.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_255.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_256.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_257.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_258.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_259.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_260.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_261.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_262.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_263.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_264.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_265.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_266.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_267.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_268.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_269.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_270.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_271.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_272.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_273.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_274.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_275.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_276.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_277.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_278.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_279.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_280.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_281.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_282.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_283.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_284.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_285.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_286.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_287.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_288.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_289.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_290.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_291.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_292.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_293.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_294.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_295.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_296.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_297.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_298.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_299.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_300.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_301.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_302.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_303.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_304.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_305.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_306.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_307.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_308.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_309.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_310.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_311.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_312.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_313.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_314.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_315.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_316.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_317.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_318.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_319.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_320.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_321.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_322.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_323.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_324.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_325.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_326.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_327.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_328.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_329.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_330.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_331.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_332.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_333.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_334.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_335.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_336.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_337.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_338.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_339.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_340.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_341.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_342.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_343.jpeg", width: 1200, height: 3600},
{path: "The_Vullos_photo_344.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_345.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_346.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_347.jpeg", width: 1080, height: 1080},
{path: "The_Vullos_photo_348.jpeg", width: 1200, height: 3600}
]